<template>
  <el-popover
    ref="popover"
    placement="bottom"
    title="Search by project or task"
    width="200"
    trigger="click"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <div
      slot="reference"
      style="padding: 0 10px"
    >
      <i
        v-if="!value"
        class="fas fa-folder-open folder-icon"
      />
      <p
        v-else
        class="assigned-task-block"
      >
        {{ value.title || value.name }}
      </p>
    </div>

    <el-select
      v-model="value"
      filterable
      remote
      value-key="uuid"
      :clearable="true"
      placeholder="Search the task"
      :remote-method="findProjectOrTaskByTitle"
      @change="assignmentTimeEntry"
    >
      <el-option-group
        v-for="group in result"
        :key="group.title"
        :label="group.title"
      >
        <el-option
          v-for="(entry, index) in group.entries"
          :key="index + 1"
          :label="entry.title || entry.name"
          :value="entry"
        />
      </el-option-group>
    </el-select>
  </el-popover>
</template>

<script>
import {
  Task,
  Project,
  TimeEntry,
  TaskRepository,
  ProjectRepository,
} from '@timeragent/core';

export default {
  name: 'FolderIcon',
  props: {
    timeEntry: {
      type: TimeEntry || [TimeEntry],
      required: true,
    },
  },
  data() {
    return {
      taskRepository: new TaskRepository(),
      projectRepository: new ProjectRepository(),
      result: [],
      value: null,
    };
  },
  watch: {
    timeEntry() {
      this.value = null;
    },
  },
  created() {
    if (this.timeEntry.length) {
      this.fetchTitle(this.timeEntry[0]);

      return;
    }

    this.fetchTitle(this.timeEntry);
  },
  methods: {
    async findProjectOrTaskByTitle(query) {
      if (query !== '') {
        this.result = [];

        await this.projectRepository.many({
          filters: {
            organizationUuid: this.organizationUuid,
          },
          withTasks: false,
          findByTitle: query,
        });

        await this.taskRepository.many({
          filters: {
            organizationUuid: this.organizationUuid,
          },
          findByTitle: query,
        });

        if (this.projectRepository.dataset.items.length) {
          this.result.push({
            title: 'Projects',
            entries: this.projectRepository.dataset,
          });
        }

        if (this.taskRepository.dataset.items.length) {
          this.result.push({
            title: 'Tasks',
            entries: this.taskRepository.dataset,
          });
        }
      } else {
        this.result = [];
      }
    },
    assignmentTimeEntry() {
      if (this.timeEntry.length) {
        this.entryAssign(this.timeEntry[0]);

        this.timeEntry.forEach(entry => {
          Object.assign(entry, {
            ...this.timeEntry[0],
            startTime: entry.startTime,
            endTime: entry.endTime,
            uuid: entry.uuid,
          });

          entry.save();

          return entry;
        });

        return;
      }

      this.entryAssign(this.timeEntry);

      if (this.timeEntry.active || this.timeEntry.endTime) {
        this.timeEntry.save();
      }
    },
    entryAssign(entry) {
      Object.assign(entry, {
        ...!this.value && {
          projectUuid: null,
          project: null,
          taskUuid: null,
          task: null,
        },
        ...this.value instanceof Task && {
          projectUuid: null,
          taskUuid: this.value.uuid,
          task: this.value,
          title: this.value.title,
        },
        ...this.value instanceof Project && {
          projectUuid: this.value.uuid,
          project: this.value,
          taskUuid: null,
        },
      });
    },
    fetchTitle(entry) {
      if (entry?.task) {
        this.value = entry.task;
        this.result.push({ title: 'Tasks', entries: [entry.task] });
      }

      if (entry?.project) {
        this.value = entry.project;
        this.result.push({ title: 'Projects', entries: [entry.project] });
      }
    },
  },
};
</script>

<style scoped>
.folder-icon {
  font-size: 16px;
  color: #bfc1c5;
}

.folder-icon:hover {
  color: #525252;
  cursor: pointer;
}

.assigned-task-block {
  border: 1px solid #409eff;
  border-radius: 15px;
  padding: 3px 13px;
  color: #409eff;
  cursor: pointer;
}

</style>
